import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["confirmInput", "showable"];
  static values = { confirmation: String };

  connect() {
    console.log("connect");
  }

  update(ev) {
    const inputValue = ev.target.value;

    if (inputValue.toUpperCase() === this.confirmationValue.toUpperCase()) {
      this.showableTarget.classList.remove("hidden");
    } else {
      this.showableTarget.classList.add("hidden");
    }
  }
}

